import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import StyledIcon from '../Icon/style'
import { spacing, colors, typography } from '../../styles/utilities/variables'

const StyledTextLink = styled(Link)`
  display: inline-flex;
  color: ${colors.primaryDarkBlue};
  padding-bottom: ${spacing.xxSmall};
  transition: color 0.3s, border 0.3s;
  border-bottom: 1px solid
    ${props => (props.underline ? colors.adaptedLightBlue : 'transparent')};

  & ${StyledIcon} {
    display: flex;
    align-items: center;
    margin-right: ${spacing.xxSmall};
  }

  &:hover {
    color: ${colors.black};
    border-bottom: 1px solid
      ${props => (props.underline ? colors.black : 'transparent')};
  }

  ${props =>
    props.inglossary &&
    css`
      font-family: ${typography.secondaryFont};
      font-size: ${typography.sizes.small};
      text-transform: capitalize;
    `}

  ${props =>
    props.active &&
    css`
      font-weight: bold;
      letter-spacing: 0.5px;
    `}
`

export default StyledTextLink
