import React, { useContext, useState, useEffect } from 'react'
import Layout from '../../views/Layout'
import SEO from '../../views/Seo'
import { graphql } from 'gatsby'
import { parseSeo } from '../../helpers/seo'
import { getUrl } from '../../helpers/url'
import {
  AppContext,
  enableCompare,
  disableCompare,
  doFetchStockStatus,
} from '../../config/context'
import { Heading } from '../../components/Heading'
import { Container } from '../../components/Container'
import { Divider } from '../../components/Divider'
import { SPACING, COLORS } from '../../helpers/constants'

import { Grid } from '../../components/Grid'
import { Col } from '../../components/Col'
import { Text } from '../../components/Text'
import { ProductCard } from '../../components/ProductCard'
import { IndentWrapper } from '../../blocks/IndentWrapper'
import { Content } from '../../components/Content'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { PageContent } from '../../components/PageContent'
import { Filter } from '../../components/Filter'
import { hasFilterMatch, hasColorFilterMatch } from '../../helpers/filter'
import { TextLink } from '../../components/TextLink'
import { useTranslation } from '../../hooks/useTranslation'

export default ({ data, pageContext }) => {
  const page = data.wordpress.page
  const { seo, title } = page

  const [state, dispatch] = useContext(AppContext)
  const { compareIsEnabled, products } = state

  const getTranslation = useTranslation()

  const series =
    data.wordpress.series && data.wordpress.series.nodes
      ? data.wordpress.series.nodes
      : null
  const filters = data.wordpress.filterSettings.acfFilterChoices.filterChoices
  const colors =
    data.wordpress.paColors && data.wordpress.paColors.nodes
      ? data.wordpress.paColors.nodes
      : null

  const toggleCompare = () => {
    !compareIsEnabled ? dispatch(enableCompare()) : dispatch(disableCompare())
  }

  /**
   * Fetch stock status
   */
  useEffect(() => {
    dispatch(doFetchStockStatus())
  }, [])

  const [activeFilterIDs, setActiveFilterIDs] = useState([])
  const [activeColorFilterIDs, setActiveColorFilterIDs] = useState([])

  return (
    <Layout>
      <SEO {...parseSeo(seo)} />
      <Breadcrumbs breadcrumbs={seo.breadcrumbs} />
      <PageContent>
        <Container>
          <IndentWrapper col={3}>
            <Heading type={'h1'} size={1}>
              {title}
            </Heading>
            <Divider size={SPACING.MEDIUM} />
            <Filter
              toggleCompare={toggleCompare}
              filters={filters}
              colors={colors}
              activeFilterIDs={activeFilterIDs}
              setActiveFilterIDs={setActiveFilterIDs}
              activeColorFilterIDs={activeColorFilterIDs}
              setActiveColorFilterIDs={setActiveColorFilterIDs}
            />
          </IndentWrapper>

          <Divider
            size={{
              desktop: SPACING.LARGE,
              tablet: SPACING.MEDIUM,
              mobile: SPACING.SMALL,
            }}
          />

          {series &&
            series.length > 0 &&
            series.map((serie, index) => {
              const {
                id: serieId,
                title,
                acfAttributes: { serieSummary },
                uri,
              } = serie

              const productsInSerie = products.filter(product => {
                const {
                  acfAttributes: { productSerie },
                } = product
                return (
                  productSerie &&
                  productSerie.id === serieId &&
                  hasFilterMatch(product, filters, activeFilterIDs) &&
                  hasColorFilterMatch(product, activeColorFilterIDs)
                )
              })

              return productsInSerie && productsInSerie.length > 0 ? (
                <React.Fragment key={serieId}>
                  <Grid col={3} mobileCol={1}>
                    <Col>
                      <Content>
                        <Heading size={3} type={'h3'}>
                          {title}
                        </Heading>
                        <Text>{serieSummary}</Text>
                        <TextLink
                          to={getUrl(uri)}
                          text={`${getTranslation('readMoreText')} ${title}`}
                          underline
                        />
                      </Content>
                    </Col>
                    <Col column={'2 / -1'}>
                      <Grid col={3} mobileCol={2}>
                        {productsInSerie.map((product, index) => {
                          const { id } = product

                          return (
                            <ProductCard
                              key={id}
                              product={product}
                              compareAllowed
                            />
                          )
                        })}
                      </Grid>
                    </Col>
                  </Grid>

                  {index < series.length - 1 && (
                    <Divider
                      line={COLORS.ADAPTED_LIGHT_BLUE}
                      size={{
                        desktop: SPACING.XX_LARGE,
                        tablet: SPACING.X_LARGE,
                        mobile: SPACING.LARGE,
                      }}
                    />
                  )}
                </React.Fragment>
              ) : null
            })}
        </Container>
      </PageContent>
    </Layout>
  )
}

export const query = graphql`
  query ProductListingQuery($id: ID!, $lang: WordPress_LanguageCodeFilterEnum) {
    wordpress {
      page(id: $id) {
        ...Single_Page
      }
      series(first: 100, where: { language: $lang }) {
        nodes {
          ...Single_Serie
        }
      }
      filterSettings(language: $lang) {
        acfFilterChoices {
          filterChoices {
            name
            children {
              nodes {
                name
                id
              }
            }
          }
        }
      }
      paColors(where: { language: $lang, orderby: TERM_ID }) {
        nodes {
          name
          id: databaseId
        }
      }
    }
  }
`
