import React from 'react'
import PropTypes from 'prop-types'
import StyledTextLink from './style'
import { Icon } from '../Icon'

const icons = ['keyboard_arrow_left', 'keyboard_arrow_right']

const TextLink = props => {
  const { icon, iconSize = 16, to, text, underline, inglossary, active } = props
  return (
    <StyledTextLink
      to={to}
      underline={underline ? 1 : 0}
      inglossary={inglossary ? 1 : 0}
      active={active ? 1 : 0}
    >
      {icon && <Icon size={iconSize} icon={icon} />}
      {text}
    </StyledTextLink>
  )
}

TextLink.propTypes = {
  icon: PropTypes.oneOf(icons),
  iconSize: PropTypes.number,
  to: PropTypes.string,
  text: PropTypes.string,
  underline: PropTypes.bool,
  inglossary: PropTypes.bool,
  active: PropTypes.bool,
}

export { TextLink }
